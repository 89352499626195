.p-filter-tab {
  background-color: #1A1A1A;
  position: relative;
  z-index: 1;
  padding: 120px 0;
  color: $white;

  @media #{$tablet} {
    padding: 80px 0;
  }
}

.p-filter-tab__header {
  text-align: center;
  max-width: 1020px;
  margin: 0 auto 60px;

  @media #{$tablet} {
    margin: 0 auto 24px;
  }
}

.p-filter-tab__main-title {
  font-size: clamp(1.5rem, 4vw, 3rem);
  letter-spacing: -0.3px;
  font-family: $primary-family-bold;
  margin-bottom: clamp(1.5rem, 3vw, 3rem);
}

.p-filter-tab__main-subtitle {
  font-size: clamp(1.25rem, 4vw, 2.25rem);
  letter-spacing: -0.2px;
  font-family: $primary-family-bold;
  margin-top: 16px;

  @media #{$phone} {
    font-family: $primary-family-medium;
  }
}

.p-filter-tab__body {
  display: flex;
  flex-wrap: wrap;
  padding: 80px 0 0;

  @media #{$tablet} {
    padding: 60px 0 0;
  }
}

.p-filter-tab__column {
  &:first-child {
    width: 45.9722%;
    padding-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$tablet} {
      width: 100%;
    }
  }

  &:last-child {
    width: 54.0277%;

    @media #{$tablet} {
      width: 100%;
    }
  }
}

.p-filter-tab__wrap {
  margin-bottom: 34px;

  @media #{$phone} {
    max-width: 266px;
    margin: 0 auto 24px;
  }
}

.p-filter-tab__list {
  display: inline-flex;
  align-items: center;
  list-style: none;
  background-color: $black;
  padding: 4px;
  border-radius: 100px;

  @media #{$phone} {
    background-color: transparent;
    border: 2px solid rgba($white, .05);
  }
}

.p-filter-tab__button {
  padding: 16px 24px;
  background-color: transparent;
  color: $black;
  border-radius: 100px;
  font-size: 1.25rem;
  font-family: $primary-family-semibold;
  letter-spacing: -0.2px;
  color: $white;
  transition: all 0.3s ease;
  @include font-size(16);
  min-width: 158px;
  text-align: center;

  @media #{$phone} {
    padding: 12px 20px;
    @include font-size(12);
    letter-spacing: -0.3px;
    min-width: auto;
  }

  &.active {
    background-color: $white;
    color: $black;
  }
}

.p-filter-tab__title {
  font-size: clamp(1.25rem, 4vw, 2.25rem);
  letter-spacing: -0.3px;
  margin-bottom: 34px;
  line-height: 1.1;

  @media #{$phone} {
    max-width: 266px;
    margin: 0 auto 48px;
  }
}

.p-filter-tab__highlight {
  font-family: $primary-family;

  font-size: clamp(1rem, 4vw, 1.375rem);
  letter-spacing: -0.39px;
  line-height: 1.1;
  margin-bottom: 64px;
  max-width: 420px;


  @media #{$tablet} {
    margin-bottom: 32px;
    max-width: 100%;
  }

  @media #{$phone} {
    max-width: 266px;
    margin: 0 auto 24px;
    letter-spacing: -0.1px;
  }
}

.p-filter-tab__description {
  letter-spacing: -0.3px;
  line-height: 1.3;
  @include font-size(18);
  max-width: 476px;


  @media #{$tablet} {
    max-width: 100%;
  }

  @media #{$phone} {
    max-width: 266px;
    margin: 0 auto 24px;
    font-style: italic;
    @include font-size(16);
    letter-spacing: -0.1px;
  }

}

.p-filter-tab__content {
  max-width: 480px;
  width: 100%;
  margin-left: auto;

  @media #{$tablet} {
    margin-left: 0;
    max-width: 100%;
  }
}

.p-filter-tab__image {
  @media #{$tablet} {
    max-width: 460px;
  }
}

.p-filter-tab__image--mobile {
  display: none;

  @media #{$phone} {
    display: block;
    max-width: 362px;
    margin: 0 auto 48px;
  }
}

.p-filter-tab__image--desktop {
  display: block;

  @media #{$phone} {
    display: none;
  }
}