.p-hero {
  background-color: $white;
  position: relative;
  z-index: 1;
  padding: clamp(5rem, 7vw, 7.5rem) 0;
}

.p-hero__content {
  text-align: center;
  position: relative;
  z-index: 2;
}

.p-hero__title {
  font-size: clamp(2rem, 6vw, 4.35rem);
  letter-spacing: -0.3px;
  margin-bottom: clamp(1.5rem, 5vw, 4rem);

  br {
    @media #{$phone} {
      display: none;
    }
  }
}

.p-hero__subtitle {
  @include font-size(28);
  font-size: clamp(1.25rem, 3vw, 1.75rem);
  line-height: 1.3;
  letter-spacing: -0.3px;
  font-family: $primary-family-bold;

  @media #{$phone} {
    font-family: $primary-family-medium;
  }
}

.p-hero__image {
  display: flex;
  justify-content: center;
  margin-top: clamp(-9.6875rem, -20vw, 2.1875rem);

  @media screen and (max-width: 860px) {
    margin-top: -1.6875rem;
  }

  @media #{$phone} {
    margin-top: 1.1875rem;
  }

  img {
    min-width: 434px;
  }
}

.p-hero__description {
  @include font-size(16);
  display: none;

  @media #{$phone} {
    display: block;
  }
}