.p-filter-graph {
  background-color: $white;
  padding: 120px 0;

  @media #{$tablet} {
    padding: 80px 0;
  }

  @media #{$phone} {
    padding: 60px 0;
  }

  .container {
    max-width: 1338px;
  }
}

.p-filter-graph__header {
  max-width: 798px;
  margin: 0 auto 118px;
  text-align: center;

  @media #{$tablet} {
    max-width: 651px;
    margin-bottom: 48px;
  }

  @media #{$phone} {
    max-width: 350px;
  }
}

.p-filter-graph__title {
  font-size: clamp(1.25rem, 4vw, 2.25rem);
  letter-spacing: -0.3px;
  line-height: 1.1;
  margin: 0 auto 32px;

  @media #{$phone} {
    margin-bottom: 24px;
    max-width: 266px;
  }
}

.p-filter-graph__highlight {
  font-size: clamp(1.25rem, 2vw, 1.375rem);
  line-height: 1.3;
  letter-spacing: -0.3px;
  font-family: $primary-family-medium;
  max-width: 560px;
  margin: 0 auto 64px;

  @media #{$phone} {
    max-width: 350px;
    margin: 0 auto 20px;
  }
}

.p-filter-graph__description {
  font-size: clamp(1rem, 1vw, 1.125rem);
  line-height: 1.3;
  letter-spacing: -0.3px;
  max-width: 100%;
  margin: 0 auto;


  @media #{$phone} {
    max-width: 350px;
  }
}

.p-filter-graph__card {
  background-color: #F7F7F7;
  border-radius: 31px;

  &:not(:last-child) {
    margin-bottom: 20px;

    @media screen and (max-width: 640px) {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 640px) {
    margin: 0 -20px;
    background-color: rgba(#E2E2E2, .2);
    background-image: linear-gradient(180deg, rgba(201, 201, 201, 0.5046393557422969) 0%, rgba(217, 217, 217, 0) 100%);
    border-radius: 15px 15px 1px 1px;
  }
}

.p-filter-graph__grid {
  display: grid;
  grid-template-columns: 450px auto;
  gap: 16px;

  @media #{$desktop} {
    grid-template-columns: 40% auto;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 100%;
  }
}

.p-graph-item {
  padding: 0 24px 80px 73px;
  border-radius: 31px;
  background-color: rgba(#D9D9D9, .5);

  @media #{$desktop} {
    padding: 0 40px 40px;
  }

  @media screen and (max-width: 640px) {
    padding: 0 24px 33px;
    background-color: transparent;
    text-align: center;
  }
}

.p-graph-item__image {
  max-width: 314px;
  margin-bottom: 84px;

  @media #{$desktop} {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 640px) {
    max-width: 256px;
    margin: 0 auto 56px;
  }
}

.p-graph-item__title {
  @include font-size(18);
  letter-spacing: -0.39px;
  line-height: 1.3;
  font-family: $primary-family-bold;
  margin-bottom: 16px;

  @media #{$tablet} {
    @include font-size(16);
  }

  @media screen and (max-width: 640px) {
    max-width: 244px;
    margin: 0 auto 16px;
  }
}

.p-graph-item__description {
  @include font-size(18);
  letter-spacing: -0.39px;
  line-height: 1.3;

  @media #{$tablet} {
    @include font-size(16);
  }

  @media screen and (max-width: 640px) {
    max-width: 244px;
    margin: 0 auto;
  }
}

.p-graph-stats {
  padding: 77px 90px 48px 45px;

  @media #{$desktop} {
    padding: 60px 40px 40px;
  }

  @media #{$tablet} {
    padding: 40px 20px 20px;
  }

  @media screen and (max-width: 640px) {
    padding: 0 20px 42px 10px;
    text-align: center;
  }
}

.p-graph-stats__title {
  @include font-size(24);
  letter-spacing: -0.39px;
  line-height: 1.3;
  font-family: $primary-family-bold;
  margin-bottom: 54px;

  @media #{$tablet} {
    @include font-size(20);
    margin-bottom: 32px;
  }

  @media screen and (max-width: 640px) {
    margin-bottom: 8px;
  }
}

.p-graph-stats__image {
  aspect-ratio: 717 / 342;
  @media screen and (max-width: 640px) {
    margin-right: -10px;
  }
}

.p-graph-stats__image--sm {
  @media screen and (max-width: 640px) {
    margin-right: -16px;
  }
}

