.p-filter-services {
  padding: 60px 0;

  @media #{$tablet} {
    background-color: #F8F8F8;
  }

  @media #{$phone} {
    padding: 80px 0;
  }

  .container {
    max-width: 1272px;
  }
}

.p-filter-services__header {
  max-width: 958px;
  margin: 0 auto 64px;
  text-align: center;

  @media #{$tablet} {
    margin-bottom: 40px;
  }

  @media #{$phone} {
    margin-bottom: 28px;
  }
}

.p-filter-services__title {
  font-size: clamp(1.5rem, 4vw, 3rem);
  line-height: 1.1;
  letter-spacing: -0.3px;
  margin-bottom: 34px;

  @media #{$tablet} {
    margin-bottom: 20px;
  }

  @media #{$phone} {
    margin-bottom: 16px;
  }
}

.p-filter-services__description {
  @include font-size(28);
  line-height: calc(28 / 18);
  font-family: $primary-family-medium;
  letter-spacing: -0.3px;

  @media #{$desktop} {
    @include font-size(24);
  }

  @media #{$tablet} {
    @include font-size(22);
  }

  @media #{$phone} {
    @include font-size(20);
  }
}

.p-filter-services__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-bottom: 16px;

  @media #{$tablet} {
    grid-template-columns: 1fr;
    max-width: 640px;
    margin: 0 auto;
  }
}

.p-filter-service {
  min-height: 511px;
  padding: 40px 22px;
  border-radius: 16px;
  background-color: var(--backgroundColor);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  @media #{$desktop} {
    min-height: 400px;
  }

  @media #{$tablet} {
    min-height: auto;
  }

  @media #{$phone} {
    padding: 36px 28px;
  }
}

.p-filter-service__header {
  width: 100%;

  @media #{$phone} {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
  }

  span {
    @media #{$phone} {
      max-width: 48px;
      width: 100%;
    }
  }
}

.p-filter-service__icon {
  margin-bottom: 20px;
  max-width: 64px;
  max-height: 56px;
  white-space: nowrap;


  @media #{$desktop} {
    max-width: 56px;
    max-height: 48px;
  }

  @media #{$phone} {
    max-width: 48px;
  }
}

.p-filter-service__title {
  @include font-size(22);
  line-height: 1.1;
  letter-spacing: -0.3px;
  font-family: $primary-family-bold;

  @media #{$desktop} {
    @include font-size(20);
  }

  @media #{$phone} {
    width: calc(100% - 68px);
    max-width: 210px;
    margin-bottom: 24px;
  }
}

.p-filter-service__body {
  overflow: hidden;
}

.p-filter-service__description {
  margin-top: auto;
  @include font-size(18);

  @media #{$desktop} {
    @include font-size(16);
  }

  @media #{$tablet} {
    padding-bottom: 20px;
  }
}

.p-filter-service__quote {
  padding: 54px;

  @media #{$tablet} {
    max-width: 640px;
    padding: 32px 0;
    margin: 0 auto;
  }

  @media #{$phone} {
    padding: 40px 21px;
    border-radius: 24px;
    background-color: $white;
    margin-top: 28px;
  }
}

.p-filter-service__quote-content {
  max-width: 998px;
  margin: 0 auto;
}

.p-filter-service__quote-text {
  @include font-size(28);
  line-height: calc(28 / 18);
  font-family: $primary-family-medium;
  letter-spacing: -0.3px;
  margin-bottom: 30px;

  @media #{$desktop} {
    @include font-size(24);
  }

  @media #{$tablet} {
    @include font-size(22);
    line-height: calc(26 / 22);
  }
}

.p-filter-service__quote-author {
  display: flex;
  gap: 16px;
  align-items: center;
}

.p-filter-service__quote-author-image {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: #f8d4ad;

  img {
    max-width: 38px;
  }
}

.p-filter-service__quote-info {
  @include font-size(18);
  line-height: calc(22 / 18);
  letter-spacing: -0.3px;
  width: calc(100% - 85px);

  span {
    display: block;
  }
}

.p-filter-service__author-name {
  font-family: $primary-family-semibold;
}

.p-filter-service__author-label {
  @include font-size(16);
  line-height: 1.3;
  letter-spacing: -0.3px;
}

.p-filter-service__accordion-button {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 9.93652L12 15.9365L6 9.93652' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-size: 24px 25px;
  background-repeat: no-repeat;
  background-position: right 16px center;
  border: none;
  background-color: transparent;
  width: 100%;
  text-align: left;
  @include font-size(14);
  font-family: $primary-family-medium;
  opacity: .8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 25px;
  line-height: .9;

  span {
    display: inline-block;
    vertical-align: top;
  }

  .is-open & {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 15.9365L12 9.93652L18 15.9365' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
  }
}