.p-filter-recommendation {
  padding: 120px 0;

  @media #{$desktop} {
    padding: 80px 0;
  }

  @media #{$phone} {
    background-color: #F8F8F8;
  }

  .container {
    max-width: 1140px;
  }
}

.p-filter-recommendation__header {
  text-align: center;
  max-width: 680px;
  margin: 0 auto 65px;

  @media #{$tablet} {
    margin-bottom: 40px;
  }

  @media #{$phone} {
    margin-bottom: 24px;
  }
}

.p-filter-recommendation__title {
  @include font-size(28);
  margin-bottom: 40px;
  letter-spacing: -0.3px;
  line-height: 1;

  @media #{$tablet} {
    @include font-size(24);
  }
}

.p-filter-recommendation__tab-list {
  display: inline-flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 8px;
  flex-wrap: wrap;
}

.p-filter-recommendation__tab-button {
  background-color: #F2F2F2;
  border: none;
  padding: 16px;
  border-radius: 40px;
  cursor: pointer;
  letter-spacing: -0.3px;
  @include font-size(16);
  font-family: $primary-family-semibold;
  transition: background-color 0.3s ease;

  @media #{$phone} {
    @include font-size(12);
  }

  &.active {
    background-color: var(--backgroundColor);
  }
}

.p-filter-recommendation__body {
  background-color: $white;
  border-radius: 20px;
  border: 2px solid #EEEEEE;
  box-shadow: 0 32px 44px rgba($black, .05);

  @media #{$phone} {
    max-width: 420px;
    margin: 0 auto;
  }
}

.p-filter-recommendation__grid {
  display: flex;
  justify-content: space-between;

  @media #{$phone} {
    flex-direction: column-reverse;
  }
}

.p-filter-recommendation__content {
  padding: 56px;

  @media #{$tablet} {
    padding: 30px 20px;
  }

  @media #{$phone} {
    padding: 48px 40px 32px;
  }
}

.p-filter-recommendation__content-title {
  @include font-size(36);
  margin-bottom: 40px;
  letter-spacing: -0.3px;
  line-height: 1.1;
  margin-bottom: 12px;

  @media #{$tablet} {
    @include font-size(24);
    margin-bottom: 24px;
  }

  @media #{$phone} {
    @include font-size(22);
    margin-bottom: 18px;
  }

  img {
    max-width: 36px;
    max-height: 40px;
    vertical-align: text-bottom;

    @media #{$tablet} {
      max-width: 24px;
    }
  }
}

.p-filter-recommendation__image {
  max-width: 350px;
  width: 100%;

  @media #{$tablet} {
    max-width: 300px;
  }

  @media #{$phone} {
    max-width: 100%;
  }

  img {
    border-radius: 15px;
  }
}

.p-filter-recommendation__content-description {
  @include font-size(18);
  line-height: 1.3;
  margin-bottom: 30px;
  max-width: 520px;
  width: 100%;

  @media #{$tablet} {
    @include font-size(16);
    margin-bottom: 24px;
  }

  @media #{$phone} {
    max-width: 268px;
  }

  strong {
    font-family: $primary-family-semibold;
  }
}

.p-filter-recommendation__content-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 36px;

  @media #{$tablet} {
    gap: 24px;
  }

  @media #{$phone} {
    flex-wrap: wrap;
    gap: 30px;
  }

  li {
    display: flex;
    align-items: center;
    gap: 18px;
    max-width: 210px;
    @include font-size(16);
    font-family: $primary-family-medium;
    line-height: 1;
    width: 100%;

    @media #{$tablet} {
      @include font-size(14);
      gap: 9px;
    }

    @media #{$phone} {
      width: 100%;
    }

    .icon {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
    }
  }
}

.p-filter-recommendation__content-button {
  display: flex;
  justify-content: center;
}