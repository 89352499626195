.p-filter-specs {
  padding: 120px 0 80px;

  @media #{$phone} {
    padding: 80px 0 0;
    background-color: #F8F8F8;
  }

  .container {
    max-width: 1340px;
  }
}

.p-filter-specs__header {
  text-align: center;
  max-width: 720px;
  margin: 0 auto;
}

.p-filter-specs__title {
  font-size: clamp(1.5rem, 4vw, 3rem);
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 34px;
  letter-spacing: -.3px;

  @media #{$phone} {
    margin-bottom: 24px;
  }

}

.p-filter-specs__description {
  font-size: clamp(1.25rem, 4vw, 1.375rem);
  line-height: 1.5;
  margin-bottom: 24px;

  @media #{$phone} {
    font-family: $primary-family-medium;
    margin-bottom: 28px;
  }
}

.p-spec-body {
  position: relative;
  z-index: 1;

  @media screen and (max-width: 860px) {
    margin: 0 -20px;
  }

  @media #{$phone} {
    background-color: var(--primaryColor);
    border-radius: 16px;
    padding: 0 15px 20px 15px;
  }
}

.p-spec-body__card {
  border-radius: 16px;
  padding: 94px 93px 62px 196px;
  background-color: var(--primaryColor);
  transition: background-color .3s ease;
  transition: height .3s ease;

  @media #{$desktop} {
    padding: 60px 60px 40px 120px;
  }

  @media screen and (max-width: 860px) {
    padding: 40px 40px 40px 100px;
  }

  @media #{$phone} {
    padding: 0;
  }
}

.p-spec-body__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media #{$phone} {
    flex-direction: column-reverse;
    padding: 0 20px;
  }

  .button--primary {
    @include font-size(18);
    font-family: $primary-family-bold;
    padding: 20px 64px;

    @media #{$desktop} {
      padding: 16px 40px;
    }

    @media #{$tablet} {
      @include font-size(16);
    }
  }

  .button--primary {
    @media #{$phone} {
      display: none;
    }
  }
}

.p-spec-slider__link {
  margin-top: 20px;
  display: none;

  @media #{$phone} {
    display: block;
    padding-left: 20px;
  }
}

.p-spec-body__header-content {
  max-width: 445px;
  width: 100%;

  @media screen and (max-width: 860px) {
    max-width: 350px;
  }

  @media #{$phone} {
    align-items: flex-start;
    max-width: 100%;
  }
}

.p-spec-body__title {
  font-size: clamp(1.5rem, 3vw, 2.25rem);
  line-height: 1.1;
  letter-spacing: -.3px;
  margin-bottom: 6px;
  position: relative;
  z-index: 2;
}

.p-spec-body__subtitle {
  font-size: clamp(1.25rem, 2.5vw, 1.75rem);
  line-height: 1.35;
  letter-spacing: -.3px;
  font-family: $primary-family-medium;
  margin-bottom: 28px;
  position: relative;
  z-index: 2;

  @media #{$tablet} {
    margin-bottom: 20px;
  }
}

.p-spec-body__description {
  font-size: clamp(1rem, 2vw, 1.375rem);
  line-height: 1.5;
  margin-bottom: 38px;
  letter-spacing: -.39px;
  opacity: .7;
  position: relative;
  z-index: 2;

  @media #{$tablet} {
    margin-bottom: 24px;
  }
}

.p-spec-body__header-img {
  width: calc(100% - 445px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 1;

  img {
    min-width: 1281px;
    height: auto;
    user-select: none;
    margin-top: -152px;

    @media #{$desktop} {
      min-width: 1000px;
      margin-top: -100px;
    }

    @media screen and (max-width: 860px) {
      min-width: 800px;
      margin-top: -70px;
    }

    @media #{$phone} {
      min-width: 671px;
      margin-top: -20px;
      transform: translateX(10px);
    }
  }
}

.p-spec-body__tab {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  margin-bottom: 18px;

  @media #{$phone} {
    justify-content: flex-start;
    gap: 10px;
    margin: 25px 0;
    padding-left: 20px;
  }
}

.p-spec-body__tab-label {
  @include font-size(18);
  letter-spacing: -0.39px;
  color: rgba($black, .5);
  margin-bottom: 0;
  line-height: 1;

  @media #{$tablet} {
    @include font-size(16);
  }

  @media #{$phone} {
    @include font-size(14);
  }
}

.p-spec-body__tab-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 2px solid rgba($black, .05);
  border-radius: 100px;
  padding: 2px;
}

.p-spec-body__tab-btn {
  @include font-size(14);
  letter-spacing: -0.3px;
  color: rgba($black, .5);
  padding: 16px 30px;
  border-radius: 100px;
  line-height: 1;
  color: $black;
  background-color: transparent;
  font-family: $primary-family-bold;
  font-weight: normal;
  transition: background-color .3s ease;

  @media #{$phone} {
    padding: 12px 20px;
    @include font-size(12);
  }

  &.active {
    background-color: $white;

    @media #{$phone} {
      box-shadow: 0 2px 6px rgba($black, .16);
    }
  }
}

.p-spec-slider {
  @media #{$phone} {
    padding: 0 20px;
  }

  .slick-list {
    @media #{$desktop} {
      margin-right: -60px;
    }

    @media screen and (max-width: 860px) {
      margin-right: -40px;
    }
  }

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;
      display: flex;
    }
  }
}

.p-spec-massonry {
  display: flex;
  gap: 10px;
}

.p-spec-item__grid--20 {
  width: 20%;
}

.p-spec-item__grid--37 {
  width: 37%;
}

.p-spec-item__grid--24 {
  width: 24%;
}

.p-spec-item__grid--39 {
  width: 39%;
}

.p-spec-item__grid--40 {
  width: 40%;
}

.p-spec-item {
  padding: 18px;
  border-radius: 7px;
  border: 2px solid rgba($black, .2);

  @media #{$phone} {
    padding: 25px 20px;
  }
}

.p-spec-item--slide {
  max-width: 211px;
  height: 100%;
  margin-right: 10px;
  min-height: 233px;

  .slick-current.slick-active & {
    border-color: rgba($white, .7);
    background-color: rgba($white, .7);
  }
}

.p-spec-item__label {
  padding: 4px 10px;
  background-color: var(--secondaryColor);
  border-radius: 100px;
  @include font-size(16);
  letter-spacing: -0.3px;
  font-family: $primary-family-semibold;
  font-weight: normal;
  color: $black;
  margin-bottom: 20px;
  text-transform: capitalize;
  line-height: calc(22/16);
  display: inline-block;
  transition: background-color .3s ease;

  @media #{$phone} {
    margin-bottom: 40px;
  }
}

.p-spec-item__description {
  @include font-size(16);
  line-height: 1.3;
  letter-spacing: -0.3px;
  color: $black;

  @media #{$phone} {
    @include font-size(14);
  }

  strong {
    font-family: $primary-family-semibold;
  }
}

.p-spec-item__grid {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  justify-content: space-between;
}

.p-spec-item-w-100 {
  width: 100%;
}

.p-spec-item-w-25 {
  width: calc(25% - 8px);
}

.p-spec-item-w-55 {
  width: calc(55% - 5px);
}

.p-spec-item-w-33 {
  width: calc(33% - 7px);
}

.p-spec-item-w-45 {
  width: calc(45% - 5px);
}

.p-spec-item-w-50 {
  width: calc(50% - 5px);
}

.p-spec-item__grid--50 {
  width: calc(36.25% - 5px);
}

.p-spec-item__grid--60 {
  width: calc(63.75% - 5px);
}

.p-spec-categories-holder {
  @media #{$phone} {
    text-align: center;
  }
}

.p-spec-categories {
  position: absolute;
  left: 30px;
  z-index: 1;
  top: 0;
  bottom: 0;
  max-width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 860px) {
    left: 15px;
  }

  @media #{$phone} {
    position: relative;
    left: auto;
    right: auto;
    gap: 10px;
    max-width: 100%;
    background-color: $white;
    border-radius: 100px;
    overflow: hidden;
    min-height: 72px;
    margin: 42px auto 0;
    display: inline-block;
  }

  .p-spec-categories__overflow {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 8px 8px 100px 8px;
    margin-bottom: -100px;

  }

  .p-spec-categories__list {
    list-style: none;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media #{$phone} {
      display: inline-flex;
      flex-direction: row;

    }
  }

  li {
    button {
      height: 56px;
      width: 56px;
      border-radius: 100px;
      background-color: rgba($white, .2);
      cursor: pointer;
      transition: background-color .3s ease, box-shadow .3s ease;

      &:hover,
      &.active {
        background-color: rgba($white, 1);
        box-shadow: 0 2.84px 8.52px rgba($black, .15);
      }

      img {
        max-width: 35px;
        height: auto;
        pointer-events: none;
        user-select: none;
      }
    }
  }
}