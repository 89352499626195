.p-filter-replacement {
  padding: 60px 0;

  @media #{$phone} {
    background-color: #F8F8F8;
  }

  .container {
    max-width: 1040px;
  }
}

.p-filter-replacement__header {
  text-align: center;
  max-width: 620px;
  margin: 0 auto 34px;

  @media #{$tablet} {
    margin: 0 auto 28px;
    max-width: 440px;
  }

  @media #{$phone} {
    max-width: 360px;
  }
}

.p-filter-replacement__title {
  font-size: clamp(1.5rem, 4vw, 3rem);
  line-height: 1.1;
  letter-spacing: -0.3px;
  margin-bottom: 34px;

  @media #{$tablet} {
    margin-bottom: 24px;
  }
}

.p-filter-replacement__description {
  @include font-size(28);
  line-height: 1.36;
  letter-spacing: -0.3px;
  font-family: $primary-family-medium;

  @media #{$tablet} {
    @include font-size(24);
  }

  @media #{$phone} {
    @include font-size(20);
  }

  br {
    @media #{$tablet} {
      display: none;
    }
  }
}

.p-filter-replacement__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.p-replacement-feature {
  position: relative;
  border-radius: 16px;
  border: 1px solid #D3D3D3;

  @media #{$tablet} {
    max-width: 306px;
    width: 100%;
    height: 100%;
  }
}

.p-replacement-feature__video {
  overflow: hidden;
  min-height: 490px;
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 16px;

  @media #{$tablet} {
    min-height: 308px;
  }

  video {
    width: 100%;
    height: auto;
    aspect-ratio: 322/490;
    object-fit: cover;
    object-position: center;
    border-radius: 16px;

    @media #{$tablet} {
      aspect-ratio: 306/308;
    }
  }
}

.p-replacement-feature__content {
  padding: 40px 30px;

  @media #{$tablet} {
    padding: 20px 16px;
  }
}

.p-replacement-feature__title {
  @include font-size(22);
  letter-spacing: -0.3px;
  line-height: 1.1;
  margin-bottom: 16px;

  @media #{$phone} {
    @include font-size(20);
  }
}

.p-replacement-feature__description {
  @include font-size(22);
  letter-spacing: -0.39px;
  line-height: 1.3;

  @media #{$tablet} {
    @include font-size(18);
  }

  @media #{$phone} {
    @include font-size(16);
  }
}

.p-filter-replacement__slider {
  .slick-dots {
    display: flex;
    justify-content: center;
    gap: 16px;
  }


  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;
      display: flex;

      @media #{$tablet} {
        margin-right: 12px;
      }
    }
  }

  .slick-dots {
    justify-content: center;
    gap: 10px;
    margin-top: 48px;

    li {
      margin: 0;
      height: auto;
      border-radius: none;

      &.slick-active {
        button {
          outline-color: $black;
          width: 24px;

          &:hover,
          &:focus {
            outline: 2px solid $black !important;
          }

          &::after {
            transform: translateX(var(--progress));
          }
        }
      }

      button {
        height: 6px;
        width: 6px;
        outline: 2px solid #d3d3d3;
        will-change: width;
        border: none;
        overflow: hidden;

        &:hover,
        &:focus {
          border-color: none;
          outline: 2px solid #d3d3d3 !important;

          &::after {
            background-color: $black;
          }
        }

        &::before {
          display: none;
        }

        &::after {
          content: "";
          height: 6px;
          border-radius: inherit;
          left: -1px;
          top: 0;
          background-color: $black;
          position: absolute;
          z-index: 1;
          width: 28px;
          transform: translateX(-100%);
        }
      }
    }
  }
}