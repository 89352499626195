.p-filter-sock {
  padding: 0 0 60px;

  @media #{$phone} {
    background-color: #F8F8F8;
  }
}

.p-filter-sock__header {
  text-align: center;
  margin-bottom: 80px;

  @media #{$tablet} {
    margin-bottom: 60px;
  }

  @media #{$phone} {
    margin-bottom: 28px;
  }
}

.p-filter-sock__title {
  font-size: clamp(1.5rem, 4vw, 3rem);
  line-height: 1.1;
  letter-spacing: -0.3px;
  margin-bottom: 34px;

  @media #{$tablet} {
    margin-bottom: 24px;
  }
}

.p-filter-sock__description {
  max-width: 720px;
  @include font-size(22);
  line-height: 1.3;
  letter-spacing: -0.39px;
  margin: 0 auto;

  @media #{$tablet} {
    @include font-size(20);
    font-family: $primary-family-medium;
  }
}

.p-filter-sock__image {
  max-width: 960px;
  margin: 0 auto;

  @media #{$desktop} {
    max-width: 700px;
  }

  @media #{$phone} {
    max-width: 480px;
  }
}