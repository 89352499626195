.p-hepa-stats {
  background-color: $white;
  padding: 0;

  @media #{$phone} {
    padding: 30px 0 0;
    background-color: #F8F8F8;
  }

  .container {
    max-width: 1288px;
    width: 100%;
  }
}

.p-hepa-stats__tab {
  text-align: center;

  @media #{$tablet} {
    margin-bottom: 16px;
  }
}

.p-hepa-stats__tab-list {
  display: inline-flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  background-color: #F8F8F8;
  border-radius: 80px;
  padding: 4px;
  margin-bottom: 32px;

  @media #{$tablet} {
    border: 2px solid rgba($black, .05);
    padding: 2px;
    margin-bottom: 0;
  }
}

.p-hepa-stats__tab-button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: $primary-family-medium;
  @include font-size(18);
  line-height: 1;
  color: $black;
  min-width: 194px;
  padding: 16px 50px;
  border-radius: 80px;
  transition: all 0.3s ease;

  @media #{$tablet} {
    padding: 12px 20px;
    @include font-size(12);
    font-family: $primary-family-semibold;
    min-width: 120px;
  }

  &.active {
    box-shadow: 0 12px 50px rgba(0, 0, 0, 0.08);
    background-color: $white;
  }
}

.p-hepa-table__body {
  z-index: 2;

  .p-hepa-table__grid {
    background-color: $white;
    overflow: hidden;
  }
}

.p-hepa-table__grid {
  display: flex;
  position: relative;
  z-index: 1;

  &:last-child {
    .p-hepa-table__body & {
      padding-bottom: 160px;
    }
  }

  &:hover {
    &::after {
      opacity: 1;

      .p-hepa-table__header & {
        opacity: 0;
      }
    }
  }

  &:last-child {
    &::after {
      bottom: 160px;
    }
  }

  &::after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    background-color: #F7F8FA;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 100px;
  }
}

.p-hepa-table__column {
  min-height: 75px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;

  @media #{$desktop} {
    min-height: 60px;
  }

  &::after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    box-shadow: 0 24px 70px rgba(0, 0, 0, 0.05);

    .p-hepa-table__body .p-hepa-table__grid:last-child & {
      bottom: -100px;
    }
  }


  .p-hepa-table__header & {
    min-height: 148px;

    @media #{$desktop} {
      min-height: 100px;
    }
  }


  &:nth-child(1) {
    width: 24.45%;
    z-index: 3;

    .p-hepa-table__body & {
      justify-content: flex-end;
      padding: 0 29px 0 36px;

      @media #{$desktop} {
        padding: 0 14px;
      }
    }
  }

  &:nth-child(2) {
    width: 11.18%;

    &::after {
      opacity: 1;

      .p-hepa-table__header & {
        border-radius: 16px 0 0 0;
      }

      .p-hepa-table__body .p-hepa-table__grid:last-child & {
        border-radius: 0 0 0 16px;
      }
    }
  }

  &:nth-child(3) {
    width: 11.18%;

    &::after {
      opacity: 1;
    }
  }

  &:nth-child(4) {
    width: 14.18%;

    &::after {
      opacity: 1;

      .p-hepa-table__header & {
        border-radius: 0 16px 0 0;
      }

      .p-hepa-table__body .p-hepa-table__grid:last-child & {
        border-radius: 0 0 16px 0;
      }
    }
  }

  &:nth-child(5) {
    width: 21.95%;
  }

  &:nth-child(6) {
    width: 17.95%;
  }
}

.p-hepa-table__column-title {
  font-family: $primary-family-medium;
  @include font-size(20);
  line-height: calc(29/20);
  color: $black;
  text-transform: capitalize;
  display: block;
  position: relative;
  z-index: 1;

  @media #{$desktop} {
    @include font-size(16);
  }
}

.p-hepa-table__label {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-right: 28px;
  gap: 16px;
  @include font-size(18);
  font-family: $primary-family-semibold;
  line-height: calc(27/18);
  width: 100%;

  @media #{$desktop} {
    @include font-size(16);
    gap: 12px;
    padding-right: 24px;
    max-width: 220px;
  }

  strong {
    font-family: inherit;
    font-weight: normal;
  }

  .tooltip {
    right: 0;
  }

  .tooltiptext {
    max-width: 150px;
  }
}

.p-hepa-table__value {
  @include font-size(16);
  letter-spacing: -0.01em;

  @media #{$desktop} {
    @include font-size(14);
  }
}

.p-hepa-table__label-img {
  max-width: 22px;
  height: auto;
  vertical-align: text-bottom;
}

//responsive
.p-hepa-table-res__header {
  overflow: hidden;
  margin: 0 -20px;
  text-align: center;
}

.p-hepa-table-res__tab {
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: -100px;
  padding-bottom: 100px;
  display: flex;
}

.p-hepa-table-res__tab-list {
  display: inline-flex;
  gap: 3px;
  padding: 10px 20px;
  margin: 0 auto;

  li {
    display: inline-block;
    color: rgba($black, .7);
    letter-spacing: -0.02em;
    line-height: 12px;
    font-family: $primary-family-semibold;
    text-align: center;
    padding: 12px 16px;
    border-radius: 10px;
    min-width: 80px;
    background-color: $white;
    box-shadow: 0 1.2px 1.12px rgba($black, .07);
    position: relative;
    flex-shrink: 0;
    @include font-size(13);

    &.active {
      background-color: $black;
      color: $white;

      &::after {
        display: block;
      }
    }

    strong,
    span {
      display: block;
      width: 100%;
      font-family: inherit;
    }

    button {
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
      color: inherit;
      font-family: inherit;
      font-size: inherit;
    }

    span {
      @include font-size(9);
    }
  }
}

.p-hepa-table-res__body {
  max-width: 640px;
  margin: 0 auto;
}

.p-hepa-table-res__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-radius: 16px;
  background-color: $white;
  padding: 16px 0;

  @media #{$phone} {
    background-color: #F8F8F8;
  }
}

.p-hepa-table-res__label {
  @include font-size(16);
  font-family: $primary-family-semibold;
  line-height: calc(27/18);
  display: flex;
  align-items: center;
  gap: 16px;
  white-space: nowrap;
  width: 60%;
}

.p-hepa-table-res__value {
  @include font-size(16);
  line-height: 1.2;
  text-align: right;
  width: 40%;
}