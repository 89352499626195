.p-filter-faqs {
  padding: 120px 0;

  @media #{$desktop} {
    padding: 80px 0;
  }

  .container {
    display: flex;
    justify-content: space-between;
    max-width: 1064px;

    @media #{$tablet} {
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}

.p-filter-faqs__title {
  font-size: clamp(1.5rem, 5vw, 3rem);
  line-height: 1.1;
  letter-spacing: -0.3px;
  max-width: 362px;

  @media #{$desktop} {
    max-width: 336px;
  }

  @media #{$tablet} {
    max-width: 300px;
  }

  @media #{$phone} {
    margin-bottom: 0;
  }
}

.p-filter-faqs__grid {
  max-width: 503px;
  width: 100%;
}

.p-filter-faqs__item {
  padding: 16px 0 0;

  &:not(:last-child) {
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 16px;
  }
}

.p-filter-faqs__question {
  @include font-size(18);
  line-height: 1.3;
  letter-spacing: -0.3px;
  margin-bottom: 16px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 1.5L8 5.5L14 1.5' stroke='black' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right center;
  cursor: pointer;
  font-family: $primary-family;
  font-weight: normal;

  @media #{$tablet} {
    @include font-size(20);
    font-family: $primary-family-medium;
  }

  button {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .is-open & {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 6.5L8 2.5L14 6.5' stroke='black' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  }

  button {
    display: block;
    width: 100%;
    text-align: left;
  }
}

.p-filter-faqs__answer {
  overflow: hidden;
  @include font-size(16);
}

.p-filter-faqs__answer-content {
  padding-bottom: 20px;
}