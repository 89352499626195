.p-product-stats {
  padding: 80px 0;
  background-color: $white;

  @media #{$phone} {
    padding: 60px 0;
    background-color: #F8F8F8;
  }

  .container {
    max-width: 1358px;
  }
}

.p-product-stats__header {
  margin: 0 auto 56px;
  max-width: 800px;
  width: 100%;
  text-align: center;

  @media #{$tablet} {
    margin-bottom: 32px;
  }

  @media #{$phone} {
    margin-bottom: 24px;
  }
}

.p-product-stats__title {
  @include font-size(48);
  letter-spacing: -0.31px;
  font-family: $primary-family-bold;
  line-height: calc(58/48);
  margin: 0 auto 34px;
  width: 100%;

  @media #{$small-screen} {
    @include font-size(36);
    margin-bottom: 28px;
  }

  @media #{$phone} {
    @include font-size(28);
  }
}

.p-product-stats__description {
  @include font-size(20);
  line-height: calc(34/20);
  max-width: 720px;
  margin: 0 auto 80px;

  @media #{$small-screen} {
    @include font-size(18);
    line-height: 1.5;
  }

  @media #{$tablet} {
    margin-bottom: 40px;
  }

  @media #{$phone} {
    @include font-size(16);
    margin-bottom: 24px;
  }
}

.p-product-stats__table {
  display: flex;
  justify-content: space-between;

  @media #{$tablet} {
    display: none;
  }
}

.p-product-stats__col--label {
  display: flex;
  align-items: flex-end;
  padding: 45px 0;

  @media #{$small-screen} {
    padding: 30px 0;
  }
}

.p-product-stats__col--values {
  width: calc(100% - 220px);
  padding: 45px 23px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 24px 70px rgba(0, 0, 0, 0.05);

  @media #{$small-screen} {
    width: calc(100% - 180px);
    padding: 30px 20px;
  }
}

.p-product-stats__column {
  flex: 1;
  padding: 20px 0 50px;
  border-radius: 10px 10px 0 0;

  @media #{$small-screen} {
    padding-bottom: 20px;
  }

  &.p-product-stats__column--current {
    background-color: var(--secondary-color)
  }
}

.p-product-stats__list-label {
  text-align: left;
  margin-right: auto;
  display: none;

  @media #{$tablet} {
    display: block;
  }
}

.p-product-stats__labels {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    min-height: 84px;
    @include font-size(16);
    font-family: $primary-family-semibold;
    letter-spacing: -0.002em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);

    @media #{$small-screen} {
      @include font-size(14);
      min-height: 64px;
    }

    small {
      font-family: $primary-family;
      font-size: 0.9375rem;
      text-transform: none;
      padding-left: 5px;
      color: rgba(0, 0, 0, 0.5);
      text-transform: none;
    }
  }
}


.p-product-stats__grid-head {
  display: flex;
  flex: 1;
  gap: 8px;

  @media #{$small-screen} {
    gap: 4px;
  }
}

.stats-item__title {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  word-wrap: break-word;
  text-align: center;
  @include font-size(18);
  font-family: $primary-family-medium;
  line-height: calc(22/18);
  text-wrap: balance;
  max-width: 132px;
  margin: 0 auto;

  img {
    max-width: 38px;
    margin-bottom: 16px;
  }
}

.p-product-stats__grid-body {
  width: 100%;

  .p-product-stats__list {
    display: flex;
    gap: 8px;
    width: 100%;
    list-style: none;
    text-align: center;
    padding: 10px 0;

    @media #{$small-screen} {
      gap: 4px;
      padding: 0;
    }

    &:last-child {
      li {
        &::after {
          border-radius: 0 0 10px 10px;
        }

        &.list-stats--hidden {
          user-select: none;
          pointer-events: none;

          &::after {
            bottom: -10px;
          }
        }
      }
    }

    li {
      letter-spacing: 0.001em;
      @include font-size(16);
      flex: 1;
      padding: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 64px;
      position: relative;
      z-index: 1;

      &.bold-text {
        font-family: $primary-family-medium;
      }

      &.list-stats--current {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: -10px;
          bottom: -10px;
          background-color: var(--secondary-color);
          z-index: -1;
        }
      }

      &.list-stats--hidden {
        color: transparent;
        user-select: none;
        pointer-events: none;

        &::after {
          content: '';
          position: absolute;
          left: 10px;
          right: 10px;
          bottom: -20px;
          top: -10px;
          z-index: -1;
          background-color: #F9F9F9;
          border-radius: 10px;
        }
      }

      &.list-stats--hidden-center {
        color: rgba(0, 0, 0, 0.3);
      }

      .list-stats--highlited {
        border-radius: 10px;
        text-align: center;
        font-family: $primary-family-medium;
        background-color: var(--primary-color);
        color: $white;
        display: block;
        width: 100%;
        padding: 13px 15px 12px;
      }
    }
  }
}

.list-stats--vertical {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  text-transform: uppercase;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  z-index: 9 !important;
}

//Slider
.p-product-stats__slider {
  display: none;
  margin: 0 -10px;

  @media #{$tablet} {
    display: block;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
  }

  .slick-dots {
    position: absolute;
    top: calc(100% + 13px);
    display: flex;
    justify-content: center;
    gap: 5px;
    left: 0;
    right: 0;

    li {
      margin: 0;

      &.slick-active {
        button {
          border-color: var(--primary-color);

          &::before {
            background-color: var(--primary-color);
            animation: move-left-to-right 5.8s linear infinite;
          }

          &:hover,
          &:focus {
            border-color: var(--primary-color);
          }
        }
      }

      button {
        border: 2px solid var(--primary-color);

        &:hover,
        &:focus {
          border-color: var(--primary-color);
        }
      }
    }
  }
}

.p-product-stats__item {
  padding: 0 10px;

  @media #{$phone} {
    padding: 0 5px;
  }
}

.p-product-stats__card {
  width: 350px;
  border-radius: 15px;
  background-color: $white;
  box-shadow: 0 14px 84px rgba(0, 0, 0, 0.08);
  color: #494949;
  padding: 32px;

  @media screen and (max-width:390px) {
    max-width: 350px;
    width: 100%;
    min-width: 300px;
  }

  .p-product-stats__list {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: flex-start;
      @include font-size(16);
      font-family: $primary-family;
      line-height: 1.4;
      border-bottom: 1px solid #F1F1F1;
      justify-content: space-between;
      padding: 10px 0;
      position: relative;
      z-index: 1;

      &.bold-text {
        font-family: $primary-family-medium;
      }

      .list-stats--highlited {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: -11px;
          right: -11px;
          background-color: var(--secondary-color);
          z-index: -1;
          border-radius: 4px;
        }
      }

      &:before {
        content: attr(data-title);
        display: inline-block;
        vertical-align: middle;
        font-family: $primary-family-medium;
      }
    }
  }
}

.p-product-stats__card-title {
  text-align: center;
  @include font-size(14);
  font-family: $primary-family-medium;
  text-transform: uppercase;

  small {
    font-family: $primary-family;
    font-size: inherit;
  }

  .icon {
    margin-left: 8px;
    height: 22px;
    width: 22px;
    color: #C7C7C7;
  }
}

.p-product-stats__tab {
  text-align: center;
}

.p-product-stats__tab-list {
  display: inline-flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  background-color: #F8F8F8;
  border-radius: 80px;
  padding: 4px;
  margin-bottom: 32px;

  @media #{$tablet} {
    border: 2px solid rgba($black, .05);
    padding: 2px;
    margin-bottom: 0;
  }
}

.p-product-stats__tab-button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: $primary-family-medium;
  @include font-size(18);
  line-height: 1;
  color: $black;
  min-width: 194px;
  padding: 16px 50px;
  border-radius: 80px;
  transition: all 0.3s ease;

  @media #{$tablet} {
    padding: 12px 20px;
    @include font-size(12);
    font-family: $primary-family-semibold;
    min-width: 120px;
  }

  &.active {
    box-shadow: 0 12px 50px rgba(0, 0, 0, 0.08);
    background-color: $white;
  }
}

//table responsive
.p-stats-table {}

.p-stats-table__header {
  overflow: hidden;
  margin: 0 -20px;
  text-align: center;
}

.p-stats-table__tab {
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: -100px;
  padding-bottom: 100px;
  display: flex;
}

.p-stats-table__tab-list {
  display: inline-flex;
  gap: 3px;
  padding: 10px 20px;
  margin: 0 auto;

  li {
    display: inline-block;
    color: rgba($black, .7);
    letter-spacing: -0.02em;
    line-height: 12px;
    font-family: $primary-family-semibold;
    text-align: center;
    padding: 12px 16px;
    border-radius: 10px;
    min-width: 80px;
    background-color: $white;
    box-shadow: 0 1.2px 1.12px rgba($black, .07);
    position: relative;
    flex-shrink: 0;

    &::after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 4.68652L3.71547e-07 0.436524L10 0.436524L5 4.68652Z' fill='black'/%3E%3C/svg%3E%0A");
      top: calc(100% - 1px);
      left: calc(50% - 5px);
      position: absolute;
      width: 10px;
      height: 5px;
      z-index: 1;
      display: none;
    }

    &.active {
      background-color: $black;
      color: $white;

      &::after {
        display: block;
      }
    }

    strong,
    span {
      display: block;
      width: 100%;
      font-family: inherit;
    }

    button {
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
      color: inherit;
      font-family: inherit;
    }

    strong {
      @include font-size(13);
    }

    span {
      @include font-size(9);
    }
  }
}

.p-stats-item {
  border-radius: 15px;
  background-color: $white;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.08);
  padding: 8px 8px 8px 0;
  min-height: 102px;
  display: flex;
  gap: 8px;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.p-stats-item__header {
  padding-left: 67px;
  position: relative;
  z-index: 1;
  align-self: center;
}

.p-stats-item__img {
  max-width: 59px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  user-select: none;
}

.p-stats-item__title {
  @include font-size(18);
  font-family: $primary-family-semibold;
  line-height: calc(22/18);
  letter-spacing: -0.3px;
  margin-bottom: 4px;

  img {
    max-width: 22px;
    vertical-align: text-bottom;
  }
}

.p-stats-item__description {
  @include font-size(14);
  font-family: $primary-family-medium;
  line-height: calc(20/14);
  letter-spacing: -0.3px;
  color: rgba($black, .6);
}

.p-stats-item__values {
  max-width: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #F4F4F4;
  padding: 10px;
  font-family: $primary-family;
  @include font-size(16);
  line-height: calc(32/24);
  letter-spacing: -0.01em;

  strong {
    font-family: $primary-family-semibold;
  }
}